import { DateRangePicker, DateRangePickerProps } from '@tremor/react'
import { DateTime } from 'luxon'
import { Flex, Select, Text } from '@radix-ui/themes'
import { useTranslation } from 'react-i18next'
import { DateRange } from '~/types'
import { useMemo } from 'react'
import { z } from 'zod'

export const SELECT_OPTIONS = {
  week: {
    label: 'dashboard.periods.week',
    value: {
      startDate: DateTime.now().minus({ days: 6 }).startOf('day'), // 7 days including today
      endDate: DateTime.now()
    }
  },
  month: {
    label: 'dashboard.periods.month',
    value: {
      startDate: DateTime.now().minus({ days: 29 }).startOf('day'), // 30 days including today
      endDate: DateTime.now()
    }
  },
  thisquarter: {
    label: 'dashboard.periods.thisquarter',
    value: {
      startDate: DateTime.now().startOf('quarter'),
      endDate: DateTime.now()
    }
  },
  thisyear: {
    label: 'dashboard.periods.thisyear',
    value: {
      startDate: DateTime.now().startOf('year'),
      endDate: DateTime.now()
    }
  },
  custom: {
    label: 'dashboard.periods.custom',
    value: {
      startDate: DateTime.now(),
      endDate: DateTime.now()
    }
  }
}

export type PeriodSelection = keyof typeof SELECT_OPTIONS

export const periodSearchParamsSchema = z.union([
  z.object({
    period: z.literal('custom' satisfies PeriodSelection),
    from: z.string().date(),
    to: z.string().date()
  }),
  z.object({
    period: z
      .enum(['week', 'month', 'thisquarter', 'thisyear'] as const satisfies PeriodSelection[])
      .default('week')
  })
])

export type PeriodSearchQuery = z.infer<typeof periodSearchParamsSchema>

// Clean interface using only DateTime objects
type PeriodSelectorProps = {
  periodSelection: PeriodSelection
  dateRange: DateRange
  onPeriodChange: (dateRange: DateRange, period: PeriodSelection) => void
} & Omit<Select.RootProps, 'onValueChange' | 'value' | 'defaultValue'>

export const PeriodSelector = ({
  periodSelection,
  dateRange,
  onPeriodChange,
  ...selectRootProps
}: PeriodSelectorProps) => {
  const { t } = useTranslation(['sites'])

  const handlePeriodSelectionChanged = (period: PeriodSelection) => {
    const newDateRange = period === 'custom' ? dateRange : SELECT_OPTIONS[period].value

    onPeriodChange(newDateRange, period)
  }

  const handleDateRangePickerChanged: DateRangePickerProps['onValueChange'] = ({ from, to }) => {
    const startDate = from ? DateTime.fromJSDate(from).startOf('day') : dateRange.startDate
    const endDate = to ? DateTime.fromJSDate(to).endOf('day') : dateRange.endDate

    onPeriodChange({ startDate, endDate }, 'custom')
  }

  return (
    <Flex gap="3">
      {periodSelection === 'custom' && (
        <DateRangePicker
          enableClear={false}
          enableSelect={false}
          selectPlaceholder={t('dashboard.periods.selectPeriod')}
          onValueChange={handleDateRangePickerChanged}
          value={{ from: dateRange.startDate.toJSDate(), to: dateRange.endDate.toJSDate() }}
        />
      )}

      <Select.Root
        size={'3'}
        value={periodSelection}
        onValueChange={handlePeriodSelectionChanged}
        {...selectRootProps}
      >
        <Select.Trigger>
          <Text size="2">{t(SELECT_OPTIONS[periodSelection].label)}</Text>
        </Select.Trigger>
        <Select.Content variant="soft" color="gray" position="popper">
          {Object.entries(SELECT_OPTIONS).map(([key, { label }]) => (
            <Select.Item key={key} value={key}>
              {t(label)}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Flex>
  )
}

// Helper hook to convert between search params and DateRange/PeriodSelection
export const usePeriodSearchParams = () => {
  // Convert from search params to DateTime objects
  const fromSearchParams = (
    searchQuery: PeriodSearchQuery
  ): { dateRange: DateRange; periodSelection: PeriodSelection } => {
    const periodSelection = searchQuery.period

    const dateRange =
      periodSelection === 'custom'
        ? {
            startDate: DateTime.fromISO(searchQuery.from),
            endDate: DateTime.fromISO(searchQuery.to)
          }
        : SELECT_OPTIONS[periodSelection].value

    return { dateRange, periodSelection }
  }

  // Convert from DateTime objects to search params
  const toSearchParams = (dateRange: DateRange, periodSelection: PeriodSelection): PeriodSearchQuery => {
    return periodSelection === 'custom'
      ? {
          period: 'custom',
          from: dateRange.startDate.toISODate()!,
          to: dateRange.endDate.toISODate()!
        }
      : { period: periodSelection }
  }

  return { fromSearchParams, toSearchParams }
}

// Helper hook for components that need a simple date range with default values
export const useDefaultDateRange = (defaultPeriod: PeriodSelection = 'week'): DateRange => {
  return useMemo(() => SELECT_OPTIONS[defaultPeriod].value, [defaultPeriod])
}
