import { Box, Button, Flex, Spinner } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '~/components/EmptyState'
import { ScrollablePageLayout } from '~/components/ScrollablePageLayout'
import { SectionCard } from '~/components/SectionCard'
import { BudgetItemGrid } from '~/features/budget/components/BudgetItemGrid'
import { BudgetItem } from '~/features/budget/components/BuggetItemForm'
import { BudgetChart } from '~/features/budget/components/HistoricalBudgetChart'
import { listSiteBudgetItems } from '~/features/budget/service'
import { getSite } from '~/features/sites/service'

const Component = () => {
  const { t } = useTranslation(['sites', 'sidenav'])
  const { siteID } = Route.useParams()

  const { data: site, isPending: sitePending } = useQuery({
    queryFn: () => getSite({ siteId: siteID }),
    queryKey: ['site', siteID]
  })

  const stationsWithCost = site?.stations.filter((s) => !!s.cost) ?? []

  const { data: invoices = [], isPending: objectivesPending } = useQuery({
    queryKey: ['site-budget-invoices', site?.id], //we use use.id to refresh the query when site is loaded
    queryFn: () => {
      if (!site) {
        return []
      }
      return listSiteBudgetItems({ siteID: site.id, type: 'historical' })
    }
  })

  const { data: objectives = [], isPending: invoicesPending } = useQuery({
    queryKey: ['site-budget-objectives', site?.id], //we use use.id to refresh the query when site is loaded
    queryFn: () => {
      if (!site) {
        return []
      }
      return listSiteBudgetItems({ siteID: site.id, type: 'objective' })
    }
  })

  // find current objective item
  const currentObjective = objectives.find(
    (o) => DateTime.fromJSDate(o.startDate) <= DateTime.now() && DateTime.fromJSDate(o.endDate) >= DateTime.now()
  )

  const isPending = sitePending || objectivesPending || invoicesPending

  return (
    <ScrollablePageLayout title={t('navigation.budgetPlanning', { ns: 'sidenav' })} className="h-[100%]">
      {isPending || !site ? (
        <Spinner />
      ) : !currentObjective ? (
        <SectionCard title="" className="h-[100%]">
          <EmptyState
            title={t('budget.overview.emptyState.title')}
            description={t('budget.overview.emptyState.description')}
            afterBody={
              <Link to={'/sites/$siteID/config/budget'} params={{ siteID }}>
                <Button size={'2'}>{'Configure Budget'}</Button>
              </Link>
            }
          />
        </SectionCard>
      ) : stationsWithCost.length === 0 ? (
        <SectionCard title="" className="h-[100%]">
          <EmptyState
            title={t('budget.overview.emptyState.title')}
            description={t('budget.overview.emptyState.description')}
            afterBody={
              <Link to={'/sites/$siteID/config/stations/new'} params={{ siteID }}>
                <Button size={'2'}>{t('stations.mapping.emptyState.addStation')}</Button>
              </Link>
            }
          />
        </SectionCard>
      ) : (
        <Flex direction="column" gap="6">
          <BudgetChart site={site} />
          <SectionCard title={t('budget.invoiceSection.title')}>
            <Box my="4">
              <BudgetItemGrid>
                {invoices.map((item) => (
                  <BudgetItem key={item.id} budgetItem={item} />
                ))}
              </BudgetItemGrid>
            </Box>
          </SectionCard>
          <SectionCard title={t('budget.objectiveSection.title')}>
            <Box my="4">
              <BudgetItemGrid>
                {objectives.map((item) => (
                  <BudgetItem key={item.id} budgetItem={item} />
                ))}
              </BudgetItemGrid>
            </Box>
          </SectionCard>
        </Flex>
      )}
    </ScrollablePageLayout>
  )
}

export const Route = createFileRoute('/sites/$siteID/budget-planning')({
  component: Component
})
