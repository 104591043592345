import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  createWaterStationSchema,
  CreateWaterStationSchema,
  stationTypeSchema,
  WaterStationSchema as updateWaterStationSchema,
  WaterStation,
  WaterStationType
} from '~/features/mapping/schemas'
import { Flex, TextField, Text, TextArea, Separator, Button, Select, Switch, Tooltip } from '@radix-ui/themes'
import { capitalize } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { SectionCard } from '~/components/SectionCard'
import { Site } from '~/types'
import { ArrowLeftIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import LinkButton from '~/components/LinkButton'
import cn from 'classnames'
import { LuEuro } from 'react-icons/lu'
import { useTranslation } from 'react-i18next'

// Define label keys that map to translation keys
const OTHER = 'other'
const ENTRY_LABEL_KEYS = ['cityWater', 'well', 'rainWater', 'recycled', OTHER]

type CommonStationFormProps = {
  isPending: boolean
  site: Site
}

type StationFormProps = CommonStationFormProps &
  (CreateStationFormProps | UpdateStationFormProps) & {
    title: string
  }

const StationForm = ({ station, onSubmit, isPending, site, title }: StationFormProps) => {
  const { t } = useTranslation('sites')
  const schema = station ? updateWaterStationSchema : createWaterStationSchema
  const [overrideCost, setOverrideCost] = useState(
    !!site.default_cost_per_m3 && !!station && station?.cost !== site.default_cost_per_m3
  )

  // Map the translation keys to actual translated labels
  const ENTRY_LABELS = ENTRY_LABEL_KEYS.map((key) => t(`stations.labels.${key}`))

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    watch
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: station || {
      site_id: site.id,
      name: '',
      description: '',
      station_type: 'entry' as WaterStationType,
      label: '',
      cost: site.default_cost_per_m3 ?? 0
    },
    mode: 'onChange'
  })

  const handleUseCostChange = (override: boolean) => {
    setOverrideCost(override)
    if (!override) {
      setValue('cost', site.default_cost_per_m3, { shouldValidate: true, shouldDirty: true })
    }
  }

  const selectedStationType = watch('station_type')
  const labelOptions = useMemo(
    () => (selectedStationType === 'entry' ? ENTRY_LABELS : []),
    [selectedStationType, t]
  )
  const [selectedLabelOption, setSelectedLabelOption] = useState(() => {
    if (station) {
      // Try to find if the station label matches any of our translated options
      const matchedLabel = ENTRY_LABELS.find((label) => station.label === label)
      if (matchedLabel) {
        return matchedLabel
      }
      // Check if it matches any keys (for cases where the app language changed)
      const matchedKey = ENTRY_LABEL_KEYS.find((key) => station.label === t(`stations.labels.${key}`))
      if (matchedKey) {
        return t(`stations.labels.${matchedKey}`)
      }
      // Otherwise use "Other"
      return t('stations.labels.other')
    }
    return ''
  })

  useEffect(() => {
    setValue('site_id', site.id, { shouldValidate: true, shouldDirty: true })
    if (!station) {
      setValue('station_type', 'entry', { shouldValidate: true, shouldDirty: true })
    }
  }, [site, setValue, station])

  useEffect(() => {
    let updatedLabelValue = selectedLabelOption
    if (labelOptions.length === 0 || selectedLabelOption === t('stations.labels.other')) {
      updatedLabelValue = station ? station.label : ''
    }
    setValue('label', updatedLabelValue, { shouldValidate: true, shouldDirty: true })
  }, [selectedLabelOption, selectedStationType, setValue, station, t])

  return (
    <SectionCard
      title={title}
      secondaryActions={
        <LinkButton
          to={'/sites/$siteID/config/stations'}
          params={{
            siteID: site.id
          }}
          text={t('stations.form.backToStations')}
          leadingIcon={<ArrowLeftIcon />}
        />
      }
    >
      <Separator size={'4'} my={'2'} />
      <Text size="2" color="gray">
        {t('stations.form.description')}
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap="6" direction="column" mt={'4'}>
          <Flex gap={'5'}>
            <Flex direction={'column'} gap="4" flexGrow={'1'}>
              <Flex direction={'column'} gap="1">
                <Text as="label" htmlFor="name" size="2" weight={'medium'}>
                  {t('stations.form.fields.name.label')}
                </Text>
                <TextField.Root
                  id="name"
                  variant="surface"
                  placeholder={t('stations.form.fields.name.placeholder')}
                  {...register('name')}
                />
              </Flex>
              <Flex gap="1" direction={'column'} flexGrow={'1'}>
                <Text size="2" weight={'medium'}>
                  {t('stations.form.fields.description.label')}
                </Text>
                <TextArea
                  rows={6}
                  variant="surface"
                  placeholder={t('stations.form.fields.description.placeholder')}
                  {...register('description', { required: false })}
                />
              </Flex>
            </Flex>

            <Flex direction={'column'} gap="4" flexGrow={'1'} maxWidth={'50%'}>
              <Flex gap="1" direction={'column'}>
                <Text size="2" as="p" weight={'medium'}>
                  {t('stations.form.fields.type.label')}
                </Text>
                <Controller
                  control={control}
                  name="station_type"
                  render={({ field }) => (
                    <Select.Root {...field} onValueChange={(v: WaterStationType) => field.onChange(v)}>
                      <Select.Trigger />
                      <Select.Content variant="soft" position="item-aligned">
                        {stationTypeSchema.options.map((option) => (
                          <Select.Item value={option} key={option}>
                            <Flex gap="2" align={'center'}>
                              {t(`stations.types.${option}`, { count: 1 })}
                            </Flex>
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </Flex>
              <Flex gap="1" direction={'column'}>
                <Text size="2" as="p" weight={'medium'}>
                  {selectedStationType === 'entry'
                    ? t('stations.form.fields.label.sourceLabel')
                    : t('stations.form.fields.label.label')}
                </Text>
                <Text size="2" as="p" color={'gray'}>
                  {t('stations.form.fields.label.description')}
                </Text>

                <Flex gap="3">
                  {labelOptions.length > 0 && (
                    <Select.Root value={selectedLabelOption} onValueChange={setSelectedLabelOption}>
                      <Select.Trigger
                        className="flex-1"
                        placeholder={t('stations.form.fields.label.selectPlaceholder')}
                      />
                      <Select.Content variant="soft" position="item-aligned">
                        {labelOptions.map((option) => (
                          <Select.Item value={option} key={option}>
                            <Flex gap="2" align={'center'}>
                              {capitalize(option)}
                            </Flex>
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                  {labelOptions.length === 0 || selectedLabelOption === t('stations.labels.other') ? (
                    <TextField.Root
                      className="flex-1"
                      variant="surface"
                      placeholder={t('stations.form.fields.label.placeholder')}
                      {...register('label')}
                    />
                  ) : null}
                </Flex>
              </Flex>
              <Text
                size="2"
                as="label"
                weight={'medium'}
                mt={'1'}
                color={!overrideCost && selectedStationType !== 'entry' ? 'gray' : undefined}
                className={cn({
                  'cursor-not-allowed': selectedStationType !== 'entry'
                })}
              >
                <Flex gap="2" align={'center'}>
                  <Switch
                    defaultChecked={overrideCost}
                    onCheckedChange={handleUseCostChange}
                    disabled={selectedStationType !== 'entry'}
                  />
                  {t('stations.form.fields.cost.overrideCost')}
                  <Tooltip content={t('stations.form.fields.cost.tooltip')}>
                    <InfoCircledIcon className="color-gray-7" />
                  </Tooltip>
                </Flex>
              </Text>
              <Flex gap="1" direction={'column'}>
                <Text
                  size="2"
                  as="p"
                  weight={'medium'}
                  color={!overrideCost || selectedStationType !== 'entry' ? 'gray' : undefined}
                >
                  {t('stations.form.fields.cost.label')}
                </Text>
                <Text size="2" as="p" color={'gray'}>
                  {t('stations.form.fields.cost.description')}
                </Text>
                <TextField.Root
                  type="number"
                  placeholder={t('stations.form.fields.cost.placeholder')}
                  min={0}
                  step={0.01}
                  disabled={!overrideCost || selectedStationType !== 'entry'}
                  {...register('cost', { valueAsNumber: true })}
                >
                  <TextField.Slot>
                    <LuEuro color={!overrideCost || selectedStationType !== 'entry' ? 'lightgray' : 'gray'} />
                  </TextField.Slot>
                </TextField.Root>
              </Flex>
            </Flex>
          </Flex>
          <Button type="submit" disabled={!isValid || !isDirty} size={'3'} loading={isPending} className="ml-auto">
            {station ? t('stations.form.buttons.update') : t('stations.form.buttons.create')}
          </Button>
        </Flex>
      </form>
    </SectionCard>
  )
}

type CreateStationFormProps = {
  station: null
  onSubmit: (data: CreateWaterStationSchema) => void
}

export const CreateStationForm = (props: CreateStationFormProps & CommonStationFormProps) => {
  const { t } = useTranslation('sites')
  const title = t('stations.form.title.create', { siteName: props.site.name })
  return <StationForm title={title} {...props} />
}

type UpdateStationFormProps = {
  station: WaterStation
  onSubmit: (data: WaterStation) => void
}

export const UpdateStationForm = (props: UpdateStationFormProps & CommonStationFormProps) => {
  const { t } = useTranslation('sites')
  const title = t('stations.form.title.update', { stationName: props.station.name })
  return <StationForm title={title} {...props} />
}
