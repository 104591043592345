import { Box, Flex, Spinner, Text, Callout, Select } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { listDataPointsForMeters } from '~/features/meters/service'
import { SiteWithStations } from '~/features/sites/service'
import { listSiteBudgetItems } from '../service'
import { L_TO_M3 } from '~/constants'
import { EmptyState } from '~/components/EmptyState'
import { LuChartColumnBig as LuBarChartBig } from 'react-icons/lu'
import { useTranslation } from 'react-i18next'
import { GoSquareFill } from 'react-icons/go'
import { ComboChart } from '~/components/Charts/ComboChart'
import { useCurrentLanguage } from '~/hooks/useCurrentLanguage'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { formatNumber } from '~/utils/formating'
import { SectionCard } from '~/components/SectionCard'

type Props = {
  site: SiteWithStations
  extraActions?: React.ReactNode
}

type BudgetChartDataPoint = {
  date: string
  measuredCost: number
  invoiceCost: number
  objectiveCost: number
}

type MeasureCostDataPoint = Pick<BudgetChartDataPoint, 'measuredCost'>
type InvoiceCostDataPoint = Pick<BudgetChartDataPoint, 'invoiceCost'>
type ObjectiveCostDataPoint = Pick<BudgetChartDataPoint, 'objectiveCost'>

type ToDateDataPoint =
  | (Omit<BudgetChartDataPoint, 'date'> & {
      diffPercent: number
      remainingPercent: number
    })
  | null

const VIEW_OPTIONS = {
  currency: '€',
  volume: 'm³'
} as const

type ViewOption = keyof typeof VIEW_OPTIONS

const makeInitialBudgetChartData = (
  startDate: DateTime,
  endDate: DateTime
): Record<string, BudgetChartDataPoint> => {
  const nMonts = endDate.endOf('month').diff(startDate.startOf('month'), 'months').months
  return Array.from({ length: nMonts + 1 }, (_, i) => {
    const date = startDate.plus({ month: i }).startOf('month')
    return {
      date: date.toISODate()!,
      measuredCost: 0,
      invoiceCost: 0,
      objectiveCost: 0
    } satisfies BudgetChartDataPoint
  }).reduce((acc, point) => ({ ...acc, [point.date]: point }), {})
}

const BudgetChart = ({ site, extraActions = null }: Props) => {
  const { t } = useTranslation('sites')
  const currentLanguage = useCurrentLanguage()

  const [viewAs, setViewAs] = useState<ViewOption>('currency')

  const stationsWithCost = site.stations.filter((station) => station.cost !== null)

  const { data: invoices, isPending: invoicesPending } = useQuery({
    queryKey: ['site-invoices', site.id],
    queryFn: () => listSiteBudgetItems({ siteID: site.id, type: 'historical' })
  })

  const { data: objectives = [], isPending: objectivesPending } = useQuery({
    queryKey: ['site-budget-objectives', site.id],
    queryFn: () => listSiteBudgetItems({ siteID: site.id, type: 'objective' })
  })

  // find current objective item
  const currentObjective = objectives.find(
    (o) => DateTime.fromJSDate(o.startDate) <= DateTime.now() && DateTime.fromJSDate(o.endDate) >= DateTime.now()
  )

  const startDate = currentObjective
    ? DateTime.fromJSDate(currentObjective.startDate)
    : DateTime.now().minus({ months: 18 }).startOf('month')
  const endDate = currentObjective
    ? DateTime.fromJSDate(currentObjective.endDate).endOf('month')
    : DateTime.now().endOf('month')

  const { data: consumptionData, isPending: consumptionPending } = useQuery({
    queryKey: ['site-budget-data', currentObjective?.id ?? 'empty', site.id],
    queryFn: () =>
      listDataPointsForMeters({
        startDate,
        endDate: DateTime.now().endOf('month'),
        meterIds: stationsWithCost.filter((s) => s.meter_id && s.cost !== null).map((s) => s.meter_id!)
      })
  })

  const measuredCostData = useMemo(() => {
    if (!consumptionData) {
      return {}
    }

    const stationData: Record<string, MeasureCostDataPoint> = _(consumptionData)
      .flatMap((m) => {
        const station = site.stations.find((s) => s.meter_id === m.id)
        if (!station) {
          return []
        }

        return _(m.datapoints)
          .groupBy((d) => d.dateTime.startOf('month').toISODate()) // group by month (resolution)
          .mapValues((points) => {
            const value =
              m.reading_method === 'incremental'
                ? _.sumBy(points, 'value')
                : (_.first(points)?.value ?? 0) - (_.last(points)?.value ?? 0)

            const converted = m.unit === 'l' ? value * L_TO_M3 : value
            return converted * (viewAs === 'volume' ? 1 : (station.cost ?? 0))
          })
          .toPairs()
          .map(([date, value]) => ({ date, value }))
          .value()
      })
      .groupBy('date')
      .mapValues((values) => ({ measuredCost: _.sumBy(values, 'value') }) satisfies MeasureCostDataPoint)
      .toPairs()
      .orderBy(([date]) => DateTime.fromISO(date), 'asc')
      .map(([date, __], idx, all) => {
        const measuredCost = _(all)
          .slice(0, idx + 1)
          .sumBy(([_, p]) => p.measuredCost)

        return [date, { measuredCost }] satisfies [string, MeasureCostDataPoint]
      }) // cumulative cost
      .fromPairs()
      .value()

    return stationData
  }, [consumptionData, site, viewAs])

  const invoiceData = useMemo(() => {
    if (!invoices) {
      return {}
    }

    const data: Record<string, InvoiceCostDataPoint> = _(invoices)
      .map((i) => ({
        ...i,
        startDate: DateTime.fromJSDate(i.startDate).startOf('month'),
        endDate: DateTime.fromJSDate(i.endDate).endOf('month')
      }))
      .flatMap((i) => {
        const months = i.endDate.diff(i.startDate, 'months').months
        const monthlyBudget = (viewAs === 'currency' ? i.cost : i.consumption) / months

        // split each invoice into distinct months and spread the cost over the months
        return _.range(months).map((month) => ({
          date: i.startDate.plus({ month }).startOf('month').toISODate(),
          value: monthlyBudget * (month + 1) // cumulative cost
        }))
      })
      .groupBy('date')
      .mapValues((values) => ({ invoiceCost: _.sumBy(values, 'value') }) satisfies InvoiceCostDataPoint)
      .value()

    return data
  }, [invoices, viewAs])

  const objectiveData = useMemo(() => {
    if (!currentObjective) {
      return {}
    }
    const start = startDate.startOf('month')
    const end = endDate.endOf('month')

    const months = Math.round(end.diff(start, 'months').months)
    const monthlyBudget = (viewAs === 'currency' ? currentObjective.cost : currentObjective.consumption) / months

    const datapoints = _.range(months)
      .map((month) => ({
        date: start.plus({ month }).toISODate()!,
        value: monthlyBudget * (month + 1) // cumulative cost
      }))
      .reduce(
        (acc, point) => ({
          ...acc,
          [point.date]: { objectiveCost: point.value } satisfies ObjectiveCostDataPoint
        }),
        {}
      )

    return datapoints
  }, [currentObjective, viewAs])

  const isPending = consumptionPending || invoicesPending || objectivesPending

  if (isPending) {
    return (
      <Flex align={'center'} justify={'center'} height={'100px'}>
        <Spinner size={'3'} />
      </Flex>
    )
  }

  if (stationsWithCost.length === 0) {
    return (
      <EmptyState
        title={t('budget.overview.emptyState.title')}
        description={t('budget.overview.emptyState.description')}
        afterBody={<LuBarChartBig size={36} color="var(--mint-11)" />}
      />
    )
  }

  if (currentObjective === undefined) {
    return (
      <EmptyState
        title={t('budget.chart.noObjectiveState.title')}
        description={t('budget.chart.noObjectiveState.description')}
        afterBody={<LuBarChartBig size={36} color="var(--mint-11)" />}
      />
    )
  }

  const InitialBudgetData = makeInitialBudgetChartData(startDate, endDate)
  // combine the data from the invoices and the consumption data
  const combined: BudgetChartDataPoint[] = _(InitialBudgetData)
    .merge(measuredCostData)
    .merge(invoiceData)
    .merge(objectiveData)
    .values()
    .filter((d) => DateTime.fromISO(d.date) < endDate)
    .value()

  const chartData = combined.map((d) => ({
    date: DateTime.fromISO(d.date).toLocaleString(
      { month: 'short', year: 'numeric' },
      { locale: currentLanguage }
    ),
    [t('budget.chart.forecastedCosts')]: _.round(d.measuredCost, 2),
    // [t('budget.chart.invoiceCosts')]: _.round(d.invoiceCost, 2),
    [t('budget.chart.objectiveCosts')]: _.round(d.objectiveCost, 2)
  }))

  // we use current month as objective is cumulative already
  const currentMonth = combined.find((d) => d.date === DateTime.now().startOf('month').toISODate())
  const toDate: ToDateDataPoint = !currentMonth
    ? null
    : {
        measuredCost: currentMonth.measuredCost,
        invoiceCost: currentMonth.invoiceCost,
        objectiveCost: currentMonth.objectiveCost,
        remainingPercent: _.clamp(
          Math.round(((currentObjective?.cost - currentMonth?.measuredCost) / currentObjective?.cost) * 100),
          0,
          100
        ),
        diffPercent: Math.round(
          ((currentMonth?.measuredCost - currentMonth?.objectiveCost) / currentMonth?.objectiveCost) * 100
        )
      }

  const remainingDays = Math.floor(DateTime.fromJSDate(currentObjective.endDate).diffNow('days').days)
  const formatViewAsLabel = (v: ViewOption) => `${t(`budget.chart.viewAs.${v}`)} ${VIEW_OPTIONS[v]}`

  return (
    <SectionCard
      title={t('budget.overview.title')}
      className="min-h[400px]"
      secondaryActions={
        <Flex align={'center'} gap={'3'}>
          <Select.Root size={'3'} value={viewAs} onValueChange={(v: ViewOption) => setViewAs(v)}>
            <Select.Trigger>{formatViewAsLabel(viewAs)}</Select.Trigger>
            <Select.Content color="gray" variant="soft">
              {Object.keys(VIEW_OPTIONS).map((viewOption) => (
                <Select.Item key={viewOption} value={viewOption}>
                  {formatViewAsLabel(viewOption as ViewOption)}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
          {extraActions}
        </Flex>
      }
    >
      <Flex direction={'column'} gap={'5'}>
        {_.isNumber(toDate?.diffPercent) && toDate?.diffPercent >= 0 && (
          <Callout.Root color={toDate.remainingPercent === 0 ? 'red' : 'amber'} className="mt-4">
            <Callout.Icon>
              <ExclamationTriangleIcon />
            </Callout.Icon>
            <Callout.Text>
              {toDate.remainingPercent === 0 ? (
                <>{t('budget.chart.surpassedBudgetAlert')}.</>
              ) : (
                <>{t('budget.chart.budgetWarningAlert', { diffPercent: toDate.diffPercent })}</>
              )}
            </Callout.Text>
          </Callout.Root>
        )}
        <Flex align={'start'} justify={'between'} py="3" px="6">
          {currentObjective && (
            <Box>
              <Flex align={'end'} gap={'1'}>
                <Text size={'3'} weight={'medium'}>
                  {t('budget.chart.currentObjective')}
                </Text>
                <GoSquareFill className="fill-amber-500" size={22} />
              </Flex>
              <Text size={'1'} color="gray" as="p">
                {DateTime.fromJSDate(currentObjective.startDate)
                  .setLocale(currentLanguage)
                  .toLocaleString({ month: 'short', year: 'numeric' })}{' '}
                {t('budget.chart.dateTo')}{' '}
                {DateTime.fromJSDate(currentObjective.endDate)
                  .setLocale(currentLanguage)
                  .toLocaleString({ month: 'short', year: 'numeric' })}
              </Text>
              <Text size={'5'} weight={'bold'}>
                {formatNumber(currentObjective.cost)} {VIEW_OPTIONS[viewAs]}
              </Text>
            </Box>
          )}
          {toDate && (
            <>
              <Box>
                <Flex align={'end'} gap={'1'}>
                  <Text size={'3'} weight={'medium'}>
                    {t('budget.chart.forecastedCosts')}
                  </Text>
                  <GoSquareFill className="fill-sky-500" size={22} />
                </Flex>
                <Text size={'1'} color="gray" as="p">
                  {DateTime.fromJSDate(currentObjective.startDate)
                    .setLocale(currentLanguage)
                    .toLocaleString({ month: 'short', year: 'numeric' })}{' '}
                  {t('budget.chart.toToday')}
                </Text>
                <Flex gap={'2'} align={'center'}>
                  <Text size={'5'} weight={'bold'}>
                    {formatNumber(toDate.measuredCost)} {VIEW_OPTIONS[viewAs]}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Flex align={'end'} gap={'1'}>
                  <Text size={'3'} weight={'medium'}>
                    {t('budget.chart.remainingBudget')}
                  </Text>
                </Flex>
                <Text size={'1'} color="gray" as="p">
                  {remainingDays} {t('budget.chart.remainingDays')}
                </Text>
                <Flex gap={'2'} align={'center'}>
                  <Text size={'5'} weight={'bold'}>
                    {formatNumber(toDate.remainingPercent)} %
                  </Text>
                </Flex>
              </Box>
            </>
          )}
        </Flex>

        <ComboChart
          className="h-[400px]"
          data={chartData}
          index="date"
          barSeries={{
            categories: [t('budget.chart.forecastedCosts')],
            yAxisLabel:
              viewAs === 'currency' ? t('budget.chart.costAxisLabel') : t('stations.leakSection.chart.yAxisLabel'),
            colors: ['sky', 'teal']
          }}
          lineSeries={{
            categories: [t('budget.chart.objectiveCosts')],
            showYAxis: true,
            colors: ['amber'],
            yAxisWidth: 60
          }}
        />
      </Flex>
    </SectionCard>
  )
}

export { BudgetChart }
