import cn from 'classnames'
import { Badge, Button, Flex, Separator, Switch, Text, TextField, Tooltip } from '@radix-ui/themes'
import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Suspense, useMemo, useState } from 'react'
import { SectionCard } from '~/components/SectionCard'
import { getSite, updateSite } from '~/features/sites/service'
import { LuEuro } from 'react-icons/lu'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { SiteSchema } from '~/features/sites/schemas'
import { updateWaterStation } from '~/features/mapping/service'
import { BudgetStationsSection } from '~/features/budget/components/BudgetStationsSection'
import { HistoricalInvoicesSection } from '~/features/budget/components/HistoricalInvoicesSection'
import { ObjectiveSection } from '~/features/budget/components/ObjectiveSection'
import { BudgetChart } from '~/features/budget/components/HistoricalBudgetChart'

const SiteBudgetPage = () => {
  const { t } = useTranslation('sites')
  const { siteID } = Route.useParams()
  const { queryClient } = Route.useRouteContext()

  const { data: site } = useSuspenseQuery({
    queryFn: () => getSite({ siteId: siteID }),
    queryKey: ['site', siteID]
  })

  const initialStationsWithCost = site.stations.filter((station) => station.cost !== null)
  const [costAdjustedStations, setUpdatedStations] = useState(initialStationsWithCost)
  const [cost, setCost] = useState(site.cost)
  const [computedCost, setComputedCost] = useState<number | null>(null)
  const [overrideCost, setOverrideCost] = useState(false)

  const { mutate: updateCostInfos, isPending } = useMutation({
    mutationFn: async () => {
      const sitePayload: SiteSchema = {
        id: siteID,
        name: site.name,
        description: site.description,
        address: site.address ?? '',
        orgID: site.org_id,
        cost: cost
      }

      await updateSite(sitePayload)

      // Update the stations using the precalculated updatedStations from the component
      for (const station of costAdjustedStations) {
        await updateWaterStation(station)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          // TODO invalidates all other queries for stations and budget items once implemented
          const isSiteQuery = `${queryKey[0]}`.includes('site') && queryKey[1] === siteID
          const isStationsQuery = `${queryKey[0]}`.includes('station') && queryKey[1] === siteID
          return isSiteQuery || isStationsQuery
        }
      })
      toast.success(t('budget.messages.updateSuccess'))
    },
    onError: (error) => {
      console.error('Error updating site', error)
      toast.error(t('budget.messages.updateError'))
    }
  })

  const handleComputedCostChange = (newComputedCost: number) => {
    setComputedCost(newComputedCost)
    const isInitial = computedCost === null
    const newOverrideCost = cost !== newComputedCost
    if (isInitial) {
      setOverrideCost(newOverrideCost)
    } else if (!overrideCost || !newOverrideCost) {
      setCost(newComputedCost)
    }
  }

  const handleOverrideCostChange = (newOverrideCost: boolean) => {
    if (!newOverrideCost) {
      setCost(computedCost)
    }
    setOverrideCost(newOverrideCost)
  }

  const hasChanges = useMemo(() => {
    const hasCostChanged = cost !== site.cost
    const stationsChange = !_.isEqual(initialStationsWithCost, costAdjustedStations)
    return hasCostChanged || stationsChange
  }, [cost, costAdjustedStations, initialStationsWithCost, site.cost])

  return (
    <Flex direction="column" gap="4">
      <SectionCard title={t('budget.title')}>
        <Separator size="4" my="2" />
        <Text size="3" weight="medium">
          {t('budget.costSection.title')}
        </Text>
        <Text size="2" color="gray" mb={'3'}>
          {t('budget.description')}
        </Text>
        <Suspense>
          <Flex gap="6">
            <Flex direction={'column'} gap="4" flexGrow={'1'} maxWidth={'55%'}>
              <Flex justify={'between'} align={'center'}>
                <Text size="2" weight={'medium'}>
                  {t('budget.costSection.costLabel')}
                </Text>
                <Badge size={'2'} color={overrideCost ? 'gray' : undefined}>
                  <Text weight={'bold'} className={cn({ 'text-gray-8 line-through': overrideCost })}>
                    {computedCost} €
                  </Text>
                </Badge>
              </Flex>
              <Text size="2" as="p" color={'gray'}>
                {t('budget.costSection.description')}
              </Text>
              <HistoricalInvoicesSection siteID={siteID} onComputedCostChange={handleComputedCostChange} />
              <Flex gap="2" justify={'between'} align={'center'}>
                <Text size="2" as="label" weight={'medium'} mt={'1'} color={!overrideCost ? 'gray' : undefined}>
                  <Flex gap="2" align={'center'} mt="1">
                    <Switch checked={overrideCost} onCheckedChange={handleOverrideCostChange} />
                    {t('budget.costSection.manualCost')}
                    <Tooltip content={t('budget.costSection.manualCostTooltip')}>
                      <InfoCircledIcon className="color-gray-7" />
                    </Tooltip>
                  </Flex>
                </Text>
                <TextField.Root
                  type="number"
                  placeholder={computedCost?.toString()}
                  value={cost ?? undefined}
                  onChange={(e) => setCost(e.target.valueAsNumber)}
                  min={0}
                  disabled={!overrideCost}
                >
                  <TextField.Slot>
                    <LuEuro color={overrideCost ? 'gray' : 'lightgray'} />
                  </TextField.Slot>
                </TextField.Root>
              </Flex>
            </Flex>
            <BudgetStationsSection site={site} newCost={cost} onStationsWithCostChange={setUpdatedStations} />
          </Flex>
          <Separator size="4" mt="6" mb={'4'} />
          <Flex direction={'column'} gap="4">
            <Text size="3" weight="medium">
              {t('budget.objectiveSection.title')}
            </Text>
            <Text size="2" color="gray">
              {t('budget.objectiveSection.description')}
            </Text>
            <ObjectiveSection siteID={siteID} siteCost={cost} />
          </Flex>
          <Button
            mt="5"
            disabled={!hasChanges}
            size={'3'}
            loading={isPending}
            onClick={() => updateCostInfos()}
            className="ml-auto"
          >
            {t('budget.updateButton')}
          </Button>
        </Suspense>
      </SectionCard>
      <BudgetChart site={site} />
    </Flex>
  )
}

export const Route = createFileRoute('/sites/$siteID/config/budget')({
  component: SiteBudgetPage
})
